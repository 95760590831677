<script setup>
import { atcb_action } from "add-to-calendar-button"
import { format } from 'date-fns-tz'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import axios from 'axios'

const props = defineProps({
    event: Object,
    listed: Boolean
})
let { page } = useContent()

if (props.event) {
    page = ref(props.event)
}

const originPath = computed(() => import.meta.client ? window.location.origin : 'pathway.com')

const data = ref([])

onMounted(async () => {
    data.value = await $fetch(`${originPath.value}/data/bootcamps/${page.value.fileName}.json`)

})

const form = ref({})

const currentStage = useState(`form-${page.value._dir}`, () => 1)

const nextStage = () => {
    verifyFields()
    if (!error.value) {
        currentStage.value = currentStage.value + 1
    }
}

const verifyFields = () => {
    const currentForm = document.getElementById(`form-${currentStage.value}`)
    const fields = currentForm.querySelectorAll("input")
    for (const el of fields) {
        if (el.required && el.value.trim() === "") {
            error.value = "Fill in all the required fields"
            break;
        }
        error.value = ""
    }
}

const prevStage = () => {
    currentStage.value = currentStage.value - 1
}

const updateForm = (newForm) => {
    form.value = newForm
}

const error = ref("")

const submitForm = async () => {
    verifyFields()
    if (!page.value?.sendTo) {
        throw Error("Form action URL (sendTo) is missing!")
    }

    if (!error.value) {
        const { $showToast } = useNuxtApp()
        await axios.post(page.value?.sendTo, form.value)
            .then(() => {
                $showToast(`Got your info. Let's start learning. Taking you to the coursework!`, 'success', 3000)
                currentStage.value = data.value.length

                return navigateTo(page.value.onSubmit)

            }).catch((e) => {
                console.log("error", e)
                $showToast('Whoops, something went wrong!', 'error', 3000)
            })
    }
}


const date = computed(() => {
    const eventDate = {}
    if (page.value.date === "today") {
        eventDate.startDate = new Date()
        return eventDate
    }
    if (Array.isArray(page.value.date)) {
        eventDate.startDate = new Date(page.value.date[0])
        eventDate.endDate = new Date(page.value.date[1])
    } else {
        eventDate.startDate = new Date(page.value.date)
    }
    return eventDate
})
const longDate = computed(() => {
    if (page.value?.textDate) {
        return page.value.textDate
    }
    let longDateText = ""
    longDateText = `${format(date.value.startDate, "EEEE")}`
    if (date.value?.endDate) {
        longDateText += `-${format(date.value.endDate, "EEEE")}`
    }
    longDateText += `, ${format(date.value.startDate, "LLLL")}`
    longDateText += ` ${format(date.value.startDate, "dd")}`
    if (date.value?.endDate) {
        longDateText += `-${format(date.value.endDate, "dd")}`
    }
    if (page.value.timezone) {
        const eventTime = date.value.startDate.toLocaleTimeString('en-US', { timeZone: page.value.timezone, timeZoneName: "short", hour: "2-digit", minute: "2-digit" })
        /* User have same timezone as event */
        if (Intl.DateTimeFormat().resolvedOptions().timeZone === page.value.timezone) {
            longDateText += ` (${eventTime})`
        } else {
            longDateText += ` (${eventTime}, ${format(date.value.startDate, 'HH:mm (z)')})`
        }
    }
    return longDateText
})

const addToCalendar = (ev) => {
    const config = {
        name: `[Reminder] ${page.value.title} (Pathway)`,
        description: `${page.value.title}, ${page.value.description}`,
        startDate: `${format(date.value.startDate, "yyyy-MM-dd")}`,
        options: ["Google", "Apple", "Outlook.com"],
        location: page.value.location.long
    }
    if (date.value?.endDate) {
        config.endDate = `${format(date.value.endDate, "yyyy-MM-dd")}`
    }
    if (page.value?.timezone) {
        config.timeZone = page.value.timezone
        const eventStartTime = date.value.startDate.toLocaleTimeString('en-US', { timeZone: page.value.timezone, hour: "2-digit", minute: "2-digit" })
        config.startTime = eventStartTime
        if (date.value?.endDate) {
            const eventEndTime = date.value.endDate.toLocaleTimeString('en-US', { timeZone: page.value.timezone, hour: "2-digit", minute: "2-digit" })
            config.endTime = eventEndTime
        }
    }
    atcb_action(config, ev.currentTarget)
}
</script>
<template>
    <div class="shadow-lg max-w-3xl w-full bg-white border-[#E0DEE2] border rounded-lg relative mx-auto my-8 eventcard">
        <div class="h-[9rem]">
            <div class="absolute flex items-center top-0 left-0 w-full p-2">
                <div class="flex gap-2 items-center drop-shadow-md ml-auto">
                    <Icon name="heroicons:calendar-solid" class="text-white w-5 h-5" />
                    <span class="text-white text-sm">{{ format(date.startDate, 'dd/MM/yyyy') }}</span>
                </div>
            </div>
            <img :src="page.banner" alt="" class="h-[9rem] w-full object-cover rounded-t-lg">
        </div>
        <div class="flex items-center flex-col md:flex-row gap-4 pw-text-black p-4">
            <div
                class="flex flex-col shrink-0 leading-6 items-center justify-center w-[4.5rem] h-[4.5rem] bg-primary-100 rounded-lg text-primary-500 font-bold">
                <span class="text-[1.2rem]">{{ format(date.startDate, 'LLL') }}</span>
                <span class="text-[1.8rem]">{{ format(date.startDate, 'dd') }}</span>
            </div>
            <div class="flex flex-wrap md:flex-nowrap justify-between items-center w-full text-center lg:text-left">
                <div class="text-center mx-auto md:mx-0 lg:text-left">
                    <nuxt-link :to="page._path" class="font-bold text-2xl">
                        <h1>{{ page.title }}</h1>
                    </nuxt-link>
                    <p class="text-[#676161] pr-1">{{ page.description }}</p>
                </div>
                <div class="mx-auto mt-4 md:mt-0">
                    <button type="button"
                        class="shadow-md hover:shadow  text-sm transition flex gap-2 items-center px-4 py-2 rounded-lg whitespace-nowrap"
                        @click="addToCalendar">
                        <Icon name="material-symbols:calendar-add-on" /> Add to Calendar
                    </button>
                </div>

            </div>
        </div>
        <div>
            <BootcampFancyForm :data="data" @formChange="updateForm" />
            <div class="bg-red-200 text-red-800 p-4 mt-4 rounded-md flex gap-2 items-center mx-4 mb-8" v-if="error">
                <Icon name="heroicons:exclamation-triangle-16-solid" class="text-xl" />
                <span class="font-bold">Error:</span> {{ error }}
            </div>
            <!-- <slot /> -->
        </div>


        <div class="border-t py-4 mx-4">
            <div class="flex flex-col md:flex-row justify-between items-center text-[#928C99] leading-5">
                <div class="flex flex-col gap-2">
                    <div class="flex gap-2 items-center">
                        <Icon name="mingcute:time-line" class="w-8 h-8" />
                        <client-only>
                            <div class="flex flex-col">
                                <span>{{ longDate }}</span>
                                <span class="text-sm" v-if="page.date !== 'today'"> {{
                                    formatDistanceToNow(date.startDate, { addSuffix: true }) }}
                                </span>
                                <span class="text-sm" v-else>Now</span>
                            </div>
                        </client-only>
                    </div>
                </div>
                <div class="flex gap-4">
                    <button type="button" class="px-6 text-gray-800 bg-gray-50 rounded-lg hover:bg-gray-200 transition"
                        @click="prevStage()" v-if="currentStage > 1">
                        Back </button>
                    <button type="button" @click="nextStage()" v-if="currentStage !== data.length"
                        class="px-6 py-4 mt-4 w-full text-center md:w-max max-w-xs md:mt-0 bg-primary-100 text-primary-500 font-bold rounded-lg hover:bg-primary-400 hover:text-white transition">
                        Next step ({{ currentStage }}/{{ data.length }})
                    </button>
                    <button type="button" @click="submitForm" v-else-if="currentStage === data.length"
                        class="px-6 py-4 mt-4 w-full text-center md:w-max max-w-xs md:mt-0 bg-primary-100 text-primary-500 font-bold rounded-lg hover:bg-primary-400 hover:text-white transition">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
